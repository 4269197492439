.map {
  height: 100vh;
  width: 100vw;
  position: absolute;
  top: 20;
  left: 0;
  z-index: 0;

}

.ol-zoom {
  /* bottom: 1em; */
  /* left: 1em; */
  /* right: auto; */
  /* top: auto; */
}


.toolbar {
  position: fixed;
  top: 50%; 
  left: 0;
  transform: translateY(-50%);
  z-index: 1;
}

.toolbar-button {
  display: block; /* Stack buttons vertically */
  margin: 10px 0; /* Spacing between buttons */
  border-radius: 50%; /* Circular buttons */
  width: 50px; /* Width of the button */
  height: 50px; /* Height of the button */
  text-align: center; /* Center the icon */
  padding: 0; /* Remove padding */
}

/* Additional styles for icons if needed */
.fa {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.mobile-panel-toggle {
  position: fixed;
  top: 10px; /* Adjust as needed */
  right: 10px; /* Adjust as needed */
  z-index: 3; /* Above the map and panel */
  display: none; /* Hidden by default */
}

/* Panel styles */
.right-panel {
  position: fixed;
  top: 56px; /* Adjust based on your navbar height */
  right: 10px;
  z-index: 2;
  transition: transform 0.3s ease-out; /* Smooth transition for the panel */
}

/* Collapsed state for the panel */
.right-panel:not(.open) {
  transform: translateX(100%); /* Hide off-screen to the right */
}

/* Show the toggle button on mobile screens */
@media (max-width: 768px) {
  .mobile-panel-toggle {
    display: block; /* Show the button on mobile */
  }

  .right-panel {
    width: 100%; 
  }
}

.react-switch {
  float: right;
}

.data-button {
  position: absolute;
  top: 70px; 
  left: 35px; 
  z-index: 1000; 
}
